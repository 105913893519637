import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { OnDemandBusRouteLeadDTO } from '@/models/dto'
import { sort } from '@/utils/sort'

const httpService: HttpService = new HttpService()

export default {
  getOnDemandBusRouteLeads(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<OnDemandBusRouteLeadDTO>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`

    const dateSort = sort()
    dateSort.add({
      prop: 'createdOn',
      direction: 'desc',
    })

    if (sorts) {
      query += `&${sorts}`
    } else {
      query += `&${dateSort.asQueryParams()}`
    }

    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const url = `https://${baseUrl()}/tables/leads/onDemandBusRoute?${query}`
    return httpService.get(url)
  },
}
