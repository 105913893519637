<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">On-Demand Bus Route Leads</h1>
  </BaseTableWrapper>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { DateTime } from 'luxon'
import onDemandBusRouteLeads from '@/services/onDemandBusRouteLeads'

const columns = [
  {
    _t_id: 'a7fc14c2',
    prop: 'fullName',
    text: 'Name',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'fullName',
    detail: false,
  },

  {
    _t_id: 'eb97e8ee',
    prop: 'phone',
    text: 'Phone',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'phone',
    detail: false,
  },

  {
    _t_id: '4e479c6f',
    prop: 'email',
    text: 'Email',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'email',
    detail: false,
  },

  {
    _t_id: 'e5f6a822',
    prop: 'companyName',
    text: 'Organization',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'companyName',
    detail: false,
  },

  {
    _t_id: 'f03ea093',
    prop: 'passengerCount',
    text: 'Passenger count',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'passengerCount',
    detail: false,
  },
  {
    _t_id: 'b0f1a6ab',
    prop: 'state',
    text: 'State',
    sort: true,
    filter: true,
    filterType: 'contains',
    type: 'text',
    sortProp: 'state',
    defaultHidden: true,
    detail: false,
  },

  {
    _t_id: '0a8da2bf',
    prop: 'createdOn',
    text: 'Date',
    sort: true,
    filter: false,
    type: 'text',
    sortProp: 'createdOn',
    defaultSort: true,
    computedText: (item) =>
      item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    detail: false,
  },

  {
    _t_id: '0a8da2bc',
    prop: 'privateShuttle',
    text: 'Private Shuttle',
    sort: true,
    filter: false,
    type: 'text',
    sortProp: 'privateShuttle',
    defaultSort: true,
    detail: false,
  },

  {
    _t_id: '0a8dbfbc',
    prop: 'bestTimeToContact',
    text: 'Best Time',
    sort: true,
    filter: false,
    type: 'text',
    sortProp: 'bestTimeToContact',
    defaultSort: true,
    computedText: (item) =>
      item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
    detail: false,
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'On-Demand Client Bus Route Leads',
    }
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        enableStatusFilterToggle: false,
        enableExport: false,
        detailKeyId: 'onDemandBusRouteLeadsId',
        tableId: 'on_demand_bus_route_leads_tv_view',
        addNewEnabled: false,
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        columns,
        collection: 'OnDemandBusRouteLeads',
        action: (params) =>
          onDemandBusRouteLeads.getOnDemandBusRouteLeads(params),
      },
    }
  },
}
</script>
